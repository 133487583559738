import { NextPage } from 'next'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'

import { CustomButtonLink } from '@/components/_custom/CustomButton'
import { Container } from '@/components/_layout/Container'
import { CustomNextSeo } from '@/components/_shared/CustomNextSeo'
import { DEVICE_WIDTH } from '@/utils/constants'
import { fullHeight } from '@/utils/style'

const NotFoundPage: NextPage = () => (
  <Container>
    <CustomNextSeo noindex title="Non trouvée / erreur 404" />

    <Wrapper>
      <FormattedMessage
        tagName={Title}
        defaultMessage="Erreur 404 : Oups !"
        description="404: title"
      />
      <FormattedMessage
        defaultMessage={`
          Visiblement la page recherchée a décidé de faire un break. Pas de panique, prenez une grande inspiration,
          expirez à fond (à répéter plusieurs fois si nécessaire, ça fait toujours du bien !) et cliquez ci-dessous
          pour revenir à l'accueil du site :
        `}
        description="404: description"
      />
      <StyledButtonLink href="/">
        <FormattedMessage
          defaultMessage="Retour à la page d'accueil"
          description="404: button"
        />
      </StyledButtonLink>
    </Wrapper>
  </Container>
)

// eslint-disable-next-line import/no-default-export
export default NotFoundPage

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  ${fullHeight.mobile}

  @media screen and (min-width: ${DEVICE_WIDTH.MOBILE}px) {
    ${fullHeight.desktop}
    max-width: 720px;
    margin: auto;
    text-align: center;
  }
`
const Title = styled.h1`
  ${({ theme }) => theme.typo.h1}

  margin-bottom: ${({ theme }) => theme.spacing.s};
`
const StyledButtonLink = styled(CustomButtonLink)`
  bottom: 0;
  margin-bottom: ${({ theme }) => theme.spacing.m};
  position: absolute;

  @media screen and (min-width: ${DEVICE_WIDTH.MOBILE}px) {
    margin-top: ${({ theme }) => theme.spacing.m};
    position: relative;
  }
`
